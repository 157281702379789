<template>
  <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden"
       v-if="details || loadingDetails">
    <div v-if="details" class="text-center sticky top-0 h-full grid grid-cols-3 w-ful gap-2 overflow-hidden">
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full overflow-y-auto">
        <div class="flex flex-col h-full">
          <div class="m-2">
            <div class="text-center">
              <div class="flex justify-between" v-if="hasTable">
                <a @click="$emit('hideTable')">
                  <svg v-if="!hideTable" class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/>
                  </svg>
                  <svg v-else class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/>
                  </svg>

                </a>
                <a @click="openItemInNewTab">
                  <svg class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3z"/>
                  </svg>
                </a>
              </div>
              <p class="text-xl mt-6">{{details.properties.get('reference')}}</p>
                <div class="text-left grid grid-cols-3 gap-x-2">
                    <div v-for="(action, index) in remainingActions">
                        <template v-if="action[0] === 'download-pdf'">
                            <custom-button @click.native="downloadPdf()" class="my-2 w-full" :loading="pdfLoading" :buttonType="getColourType(action[0], index)">
                                <!--                                <svg class="w-6 h-6 text-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>-->
                                Download PDF
                            </custom-button>
                        </template>
                        <template v-else-if="!action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <form-action :title="getTitle(action[0], action.title)" :response="details" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)" @success="formSuccess"></form-action>
                        </template>
                        <template v-else-if="action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <confirmation-action :title="getTitle(action[0], action.title)" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="action[0] === 'delete-template'" :properties="details.properties" :buttonType="getColourType(action[0], index)" :on-success-path="action[0] === 'delete-payment-voucher' ? '/payment-vouchers': $router.currentRoute.fullPath" @success="formSuccess"

                            ></confirmation-action>
                        </template>
                    </div>
                </div>
              <header-link class="my-4" @click="openTab('details')" :isActive="tabOpen === 'details'" activeClass="bg-v3-orange bg-opacity-20">
                <template v-slot:title>
                  <div class="text-left">
                    Details
                  </div>
                </template>
              </header-link>
                <header-link class="my-4" @click="openTab('questions')" :isActive="tabOpen === 'questions'" activeClass="bg-v3-orange bg-opacity-20">
                    <template v-slot:title>
                        <div Class="text-left">
                            Questions & Answers
                        </div>
                    </template>
                </header-link>
                <header-link class="my-4" @click="openTab('images')" :isActive="tabOpen === 'images'" activeClass="bg-v3-orange bg-opacity-20">
                    <template v-slot:title>
                        <div Class="text-left">
                            Images
                        </div>
                    </template>
                </header-link>
            </div>
          </div>

        </div>
      </div>
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full col-span-2 overflow-y-auto text-left">
        <div class="p-2 h-full w-full">
          <div v-if="tabOpen === 'details'">
              <pre-check-details-pagination :entity="details" :editing="editing" :collapsable="false" class="my-2"
                                          :action="updateAction"
                                          :updateActionData="updateActionData"  @editClicked="editDetails" @saveClicked="saveDetails" @success="formSuccess"></pre-check-details-pagination>
          </div>
            <div v-if="tabOpen === 'questions'">
                <qa-questions-pagination ref="questions" :category="details" :editing="editingQuestions"
                                         @editClicked="updateEditingQuestions" @saveClicked="saveQuestions"
                                        :updateAction="details.actions.filter(action => action.name === 'update-pre-check').first()">
                </qa-questions-pagination>
            </div>

            <div v-if="tabOpen === 'images'" class="grid gap-2" :class="{'grid-cols-2': hasTable && !hideTable, 'grid-cols-3': !hasTable || hideTable}">
                <div v-if="details.properties.get('on_site_confirmation_image')">
                    <collapsable-header :collapsable="false"><template v-slot:title>On Site Confirmation Image</template></collapsable-header>
                    <dd class="mt-1 text-sm font-bold text-black">
                        <div
                            class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                            <a :href="details.properties.get('on_site_confirmation_image')" class="pointer-events-none">
                                <img class="group-hover:opacity-75 object-cover pointer-events-none rounded-lg max-w-full" :src="details.properties.get('on_site_confirmation_image')" alt="">
                            </a>
                        </div>
                    </dd>
                </div>
                <div v-if="details.properties.get('construction_drawing')">
                    <collapsable-header :collapsable="false"><template v-slot:title>Construction Drawing</template></collapsable-header>
                    <dd class="mt-1 text-sm font-bold text-black">
                        <div
                            class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                            <a v-if="!details.properties.get('construction_drawing') || !details.properties.get('construction_drawing').endsWith('.pdf')"
                               :href="details.properties.get('construction_drawing')" class="pointer-events-none">
                                <img class="group-hover:opacity-75 object-cover pointer-events-none rounded-lg max-w-full" :src="details.properties.get('construction_drawing')" alt="">
                            </a>
                            <span v-else>
                        <a class="text-sm text-gray-700 underline" href="#"
                           @click.prevent="showConstructionDrawingPDF = true">Download Construction Drawing</a><br>
                        <template v-if="showConstructionDrawingPDF">
                            <download-pdf
                                :url="details.properties.get('construction_drawing')"
                                @removeFromDisplay="showConstructionDrawingPDF = false"></download-pdf>
                        </template>
                    </span>
                        </div>
                    </dd>
                </div>
                <div v-for="(preCheckImage, index) in details.properties.get('pre_check_images')">
                    <collapsable-header :collapsable="false">
                        <template v-slot:title>Pre-Check Image {{index+1}}</template>
                    </collapsable-header>
                    <dd class="mt-1 text-sm text-gray-900">
                        <div
                            class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                            <a :href="preCheckImage" class="pointer-events-none">
                                <img class="group-hover:opacity-75 object-cover pointer-events-none rounded-lg max-w-full" :src="preCheckImage" alt="">
                            </a>
                        </div>
                    </dd>
                </div>
                <div v-for="(additionalImage, index) in details.properties.get('additional_images')">
                    <collapsable-header :collapsable="false"><template v-slot:title>Additional Image {{index+1}}</template></collapsable-header>

                    <dd class="mt-1 text-sm text-gray-900">
                        <div
                            class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                            <a :href="additionalImage" class="pointer-events-none">
                                <img class="group-hover:opacity-75 object-cover pointer-events-none rounded-lg max-w-full" :src="additionalImage" alt="">
                            </a>
                        </div>
                    </dd>
                </div>
            </div>

        </div>
      </div>
    </div>
    <loading v-else class="sticky top-0"></loading>

      <modal v-if="showModal" @removeFromDisplay="showModal = false" @perform="showModal = false" title="Unsaved Changes" confirmationString="" :has-confirmation-action="false">
          Please save changes before changing page.
      </modal>
  </div>
</template>
<script>
import BlankHeader from "@/v3/components/headers/BlankHeader.vue"
import HeaderLink from "@/v3/components/headers/HeaderLink.vue"
import Loading from '@/components/Loading'
import {mapGetters} from "vuex";
import fileDownload from "js-file-download";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import VoucherDetailsPagination from "@/v3/components/pagination/VoucherDetailsPagination.vue";
import VoucherActivityFeed from "@/v3/components/VoucherActivityFeed.vue";
import PaymentVoucherQas from "@/components/PaymentVoucherQas.vue";
import VoucherQaPagination from "@/v3/components/pagination/VoucherQaPagination.vue";
import VoucherDayPayPagination from "@/v3/components/pagination/VoucherDayPayPagination.vue";
import VoucherPreviewPagination from "@/v3/components/pagination/VoucherPreviewPagination.vue";
import QaCategoryDetailsPagination from "@/v3/components/pagination/QaCategoryDetailsPagination.vue";
import QuestionsInput from "@/components/Action/Fields/QuestionsInput.vue";
import QaCategoriesPreCheckQuestionsPagination
    from "@/v3/components/pagination/QaCategoriesPreCheckQuestionsPagination.vue";
import QaCategoriesQuestionsPagination from "@/v3/components/pagination/QaCategoriesQuestionsPagination.vue";
import QaCategoriesPaymentTypesPagination from "@/v3/components/pagination/QaCategoriesPaymentTypesPagination.vue";
import OrderActivityFeed from "@/v3/components/OrderActivityFeed.vue";
import QaDetailsPagination from "@/v3/components/pagination/QaDetailsPagination.vue";
import DownloadPdf from "@/pages/DownloadPdf.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import QaQuestionsPagination from "@/v3/components/pagination/QaQuestionsPagination.vue";
import QaPaymentTypesPagination from "@/v3/components/pagination/QaPaymentTypesPagination.vue";
import QualityAssessmentComments from "@/v2/components/QualityAssessmentComments.vue";
import QualityAssessmentTimeline from "@/v2/components/QualityAssessmentTimeline.vue";
import QualityAssessmentActivityFeed from "@/v3/components/QualityAssessmentActivityFeed.vue";
import PreCheckDetailsPagination from "@/v3/components/pagination/PreCheckDetailsPagination.vue";
import Modal from "@/v3/components/modals/Modal.vue";

export default {
  name: 'SinglePreCheckDetails',
  components: {
      Modal,
      PreCheckDetailsPagination,
      QualityAssessmentActivityFeed,
      QualityAssessmentTimeline,
      QualityAssessmentComments,
      QaPaymentTypesPagination,
      QaQuestionsPagination,
      CollapsableHeader,
      DownloadPdf,
      QaDetailsPagination,
      OrderActivityFeed,
      QaCategoriesPaymentTypesPagination,
      QaCategoriesQuestionsPagination,
      QaCategoriesPreCheckQuestionsPagination,
      QuestionsInput,
      QaCategoryDetailsPagination,
      VoucherPreviewPagination,
      VoucherDayPayPagination,
      VoucherQaPagination,
      PaymentVoucherQas,
      VoucherActivityFeed,
      VoucherDetailsPagination,
      CustomButton,
       FormAction, ConfirmationAction,
    BlankHeader,
    HeaderLink,
    Loading
  },
    data(){
      return {
          editing: false,
          editingOrderItems: false,
          tabOpen: 'details',
          pdfLoading: false,
          editingPreCheckQuestions: false,
          savingPreCheckQuestions: false,
          editingQuestions: false,
          savingQuestions: false,
          showConstructionDrawingPDF: false,
          showModal: false
      }
    },
    props:{
      details: {
          type: Object,
          default: null
      },
        hideTable: {
          type: Boolean,
            default: false
        },
        loadingDetails:{
          type: Boolean,
            default: false
        },
        hasTable:{
          type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        initials() {
            if (this.orderUser.entity && this.orderUser.entity.properties.get('name')) {
                return this.orderUser.entity.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        orderUser(orderEntity) {
            let orderUser = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("user");
                })
                .first();

            if (!orderUser) {
                return null;
            }

            return orderUser;
        },
        updateAction() {
            if (!this.details) {
                return null;
            }
            return this.details.actions.filter(action => action.name === 'update-quality-assessment').first()
        },
        updateActionData() {
            if (!this.updateAction || !this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        remainingActions() {
            return this.details.actions
                .filter(action =>
                    action.name !== 'update-quality-assessment' &&
                    action.name !== 'update-additional-image' &&
                    action.name !== 'add-comment' &&
                    action.name !== 'update-pre-check'
                );
        }
    },
    methods:{
        openItemInNewTab(){
            let selfLink = this.details.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            window.open(parts[1], '_blank');
        },
        editDetails(){
            this.editing = true;
        },
        updateEditingPreCheckQuestions(){
            this.editingPreCheckQuestions =true;
        },
        updateEditingQuestions(){
            this.editingQuestions =true;
        },
        saveDetails() {
            if(!this.submittingModal) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submittingModal = true;
                this.updateAction.perform(this.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.editing = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        async savePreCheckQuestions(){
            if (!this.savingPreCheckQuestions) {
                this.savingPreCheckQuestions = true;
                this.$refs.preCheckQuestions.saveItems().then(res => {
                    this.editingPreCheckQuestions = false;
                    this.savingPreCheckQuestions = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The pre-check questions were saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.savingPreCheckQuestions = false;
                })
            }
        },
        async saveQuestions(){
            if (!this.savingQuestions) {
                this.savingQuestions = true;
                this.$refs.questions.saveItems().then(res => {
                    this.editingQuestions = false;
                    this.savingQuestions = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The questions were saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.savingQuestions = false;
                })
            }
        },
        removeItem(){
            let entity = {'entity': this.details};
            this.$emit('setLoading');
            this.$emit('getDetails', entity);
        },
        getTitle(name, title){
            if(name === 'delete-qa'){
                return 'Delete';
            }
            if(name === 'approve-qa'){
                return 'Approve';
            }
            return title;
        },
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        formSuccess(res){
            if(res.body) {
                let details = res.body;
                let entity = {'entity': details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);
            }
        },
        downloadPdf(){
            this.pdfLoading = true;
            this.details.actions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf').first().perform().responseType('blob').then(res => {
                var fileURL = window.URL.createObjectURL(res.xhr.response);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                let pdfName = this.details.properties.get('pdf_name');
                fileLink.setAttribute('download', pdfName + '.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
                this.pdfLoading = false;
            }).catch(error => {
                this.pdfLoading = false;
                alert('There was an error processing your request.' + error);
                this.$emit('failure');
            });
        },
        openTab(tabName){
            if(!this.editing && !this.editingQuestions){
                this.tabOpen = tabName;
            }else{
                this.showModal = true;
                this.showModal = true;
            }
        },
        getImageLink(image){
            return image.replace(process.env.VUE_APP_API_URL, '');
        }
    }
}
</script>
